<template>
  <div>
    <h1 class="Title">Your TimeTable</h1>
    <div class="selectors">
      <select name="" id="" v-model="branch">
        <option value="CSE 1" selected>CSE 1</option>
        <option value="CSE 2">CSE 2</option>
        <option value="CSE 3">CSE 3</option>
        <option value="IT 1">IT 1</option>
        <option value="IT 2">IT 2</option>
        <option value="IT 3">IT 3</option>
        <option value="ECE 1">ECE 1</option>
        <option value="ECE 2">ECE 2</option>
        <option value="ECE 3">ECE 3</option>
        <option value="AIML">AIML</option>
        <option value="AIDS">AIDS</option>
      </select>

      <select name="" id="" v-model="year">
        <option value="1st" selected>1st Year</option>
        <option value="2nd">2nd Year</option>
        <option value="3rd">3rd Year</option>
        <option value="4th">4th Year</option>
      </select>
    </div>
    <div v-if="!notFound">
      <div class="upcoming">
        <div class="floating-box"><span>Current Class</span>Text Holder</div>
        <div class="floating-box"><span>Upcoming</span>Text Holder</div>
      </div>
      <div class="heading" v-if="!notFound">
        <h1>
          {{ branch }} <span>{{ year }} Year</span>
        </h1>
        <h1>Location: {{ home }}</h1>
      </div>
      <div class="grid" v-if="!notFound">
        <div class="head">8-9</div>
        <div class="head">9-10</div>
        <div class="head">10-11</div>
        <div class="head">11-12</div>
        <div class="head">12:30-1:30</div>
        <div class="head">1:30-2:30</div>
        <div class="head">2:30-3:30</div>
        <!-- Monday -->
        <div
          v-for="i in monday"
          :key="i"
          :class="day == 1 ? 'ongoing' + ' ' + i.size : i.size"
        >
          {{ i.subject }}
        </div>
        <!-- Tuesday -->
        <div
          v-for="i in tuesday"
          :key="i"
          :class="day == 2 ? 'ongoing' + ' ' + i.size : i.size"
        >
          {{ i.subject }}
        </div>
        <div
          v-for="i in wednesday"
          :key="i"
          :class="day == 3 ? 'ongoing' + ' ' + i.size : i.size"
        >
          {{ i.subject }}
        </div>
        <div
          v-for="i in thursday"
          :key="i"
          :class="day == 4 ? 'ongoing' + ' ' + i.size : i.size"
        >
          {{ i.subject }}
        </div>
        <div
          v-for="i in friday"
          :key="i"
          :class="day == 5 ? 'ongoing' + ' ' + i.size : i.size"
        >
          {{ i.subject }}
        </div>
      </div>
      <!-- Wednesday -->
      <!-- <div>TOC Theory</div>
      <div>Maths Theory</div>
      <div class="joined-3">MATHS LAB / CS LAB</div>
      <div>OOPS Theory</div>
      <div>COA Theory</div>
      <div>CS Theory</div> -->
      <!-- Thursday -->
      <!-- <div>TOC Theory</div>
      <div>Maths Tutorial</div>
      <div>DBMS Theory</div>
      <div>Maths Theory</div>
      <div>Maths Theory</div>
      <div>DBMS Theory</div>
      <div>COA Theory</div> -->
      <!-- Friday -->
      <!-- <div class="joined-4">OOPS LAB</div>
      <div>CS Theory</div>
      <div>OOPS Theory</div>
      <div class="joined-5">DBMS LAB / Maths Lab</div>
      <div>CS Tutorial</div> -->
    </div>
    <div v-if="notFound" class="notFound">
      <h1>OOPSIE The TimeTable you're looking for isn't here yet...</h1>
      <img
        src="https://i.pinimg.com/originals/ef/8b/bd/ef8bbd4554dedcc2fd1fd15ab0ebd7a1.gif"
        alt=""
      />
    </div>
    <footer>Created By <span>Ikjyot</span> and <span>Yash</span></footer>
  </div>
</template>

<script>
import { timetables } from "./data.js";
export default {
  name: "App",
  data() {
    return {
      branch: "CSE 1",
      year: "1st",
      timetable: [],
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      home: null,
      day: null,
      notFound: false,
    };
  },
  watch: {
    branch: function () {
      this.monday = null;
      this.tuesday = null;
      this.wednesday = null;
      this.thursday = null;
      this.friday = null;
      this.home = null;
      let a = timetables[this.year.slice(0, 1)];
      console.log(a);
      if (a.length <= 0) {
        this.notFound = true;
      } else {
        let found = 0;
        a.map(async (section) => {
          if (section[this.branch]) {
            found = 1;
            this.timetable = section[this.branch];
            let a = JSON.parse(JSON.stringify(this.timetable));
            this.monday = a[0].Monday;
            this.tuesday = a[1].Tuesday;
            this.wednesday = a[2].Wednesday;
            this.thursday = a[3].Thursday;
            this.friday = a[4].Friday;
            this.home = a[5].Home;
            this.notFound = false;
          }
          if (found == 0) {
            this.notFound = true;
          }
        });
      }
    },
    year: function () {
      let a = timetables[this.year.slice(0, 1)];
      console.log(a);
      if (a.length <= 0) {
        this.notFound = true;
      } else {
        a.map(async (section) => {
          if (section[this.branch]) {
            this.timetable = section[this.branch];
            let a = JSON.parse(JSON.stringify(this.timetable));
            this.monday = a[0].Monday;
            this.tuesday = a[1].Tuesday;
            this.wednesday = a[2].Wednesday;
            this.thursday = a[3].Thursday;
            this.friday = a[4].Friday;
            this.home = a[5].Home;
            this.notFound = false;
          } else {
            this.timetable = [];
          }
        });
      }

      // console.log(timetables[this.year.slice(0, 1)]);
    },
  },
  mounted() {
    let c = new Date();
    console.log(c.getDay());
    this.day = c.getDay();
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: monospace;
}
.Title {
  color: black;
  font-size: 2.3rem;
  margin-top: 2rem;
}
.heading span {
  color: #04d99d;
}
.current-timetable {
  color: #04d99d;
}
.selectors {
  width: 100vw;
  height: 30vh;
  flex-direction: row;
  padding: 1rem;
}
.selectors select {
  padding: 1rem;
  margin: 1rem;
}
select:hover {
  cursor: pointer;
}
div {
  border: 1px solid white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  border-radius: 5px;
}
.head {
  color: black;
  background-color: white;
}
.grid {
  margin: auto auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  width: max(90vw, 850px);
  height: max(80vh, 600px);
  background-color: black;
  place-items: center;
  gap: 5px;
  padding: 1rem;
}
/* .grid div:hover {
    color: black;
    background-color: #04d99d;
    cursor: pointer;
  } */
.joined-235 {
  grid-column: 3/5;
  grid-row: 2;
  color: greenyellow;
}
.joined-257 {
  grid-column: 5/7;
  grid-row: 2;
  color: greenyellow;
}
.joined-213 {
  grid-column: 1/3;
  grid-row: 2;
  color: greenyellow;
}
.joined-224 {
  grid-column: 2/4;
  grid-row: 2;
  color: greenyellow;
}
.joined-212 {
  grid-column: 1/2;
  grid-row: 2;
  color: greenyellow;
}
.joined-268 {
  grid-column: 6/8;
  grid-row: 2;
  color: greenyellow;
}
.joined-313 {
  grid-column: 1/3;
  grid-row: 3;
  color: greenyellow;
}
.joined-357 {
  grid-column: 5/7;
  grid-row: 3;
  color: greenyellow;
}
.joined-335 {
  grid-column: 3/5;
  grid-row: 3;
  color: greenyellow;
}
.joined-368 {
  grid-column: 6/8;
  grid-row: 3;
  color: greenyellow;
}
.joined-324 {
  grid-column: 2/4;
  grid-row: 3;
  color: greenyellow;
}
.joined-435 {
  grid-column: 3/5;
  grid-row: 4;
  color: greenyellow;
}
.joined-424 {
  grid-column: 2/4;
  grid-row: 4;
  color: greenyellow;
}
.joined-468 {
  grid-column: 6/8;
  grid-row: 4;
  color: greenyellow;
}
.joined-413 {
  grid-column: 1/3;
  grid-row: 4;
  color: greenyellow;
}
.joined-457 {
  grid-column: 5/7;
  grid-row: 4;
  color: greenyellow;
}
.joined-434 {
  grid-column: 3/4;
  grid-row: 4;
  color: greenyellow;
}
.joined-445 {
  grid-column: 4/5;
  grid-row: 4;
  color: greenyellow;
}
.joined-524 {
  grid-column: 2/4;
  grid-row: 5;
  color: greenyellow;
}
.joined-568 {
  grid-column: 6/8;
  grid-row: 5;
  color: greenyellow;
}
.joined-557 {
  grid-column: 5/7;
  grid-row: 5;
  color: greenyellow;
}
.joined-513 {
  grid-column: 1/3;
  grid-row: 5;
  color: greenyellow;
}
.joined-535 {
  grid-column: 3/5;
  grid-row: 5;
  color: greenyellow;
}
.joined-512 {
  grid-column: 1/2;
  grid-row: 5;
  color: greenyellow;
}
.joined-513 {
  grid-column: 1/3;
  grid-row: 5;
  color: greenyellow;
}
.joined-557 {
  grid-column: 5/7;
  grid-row: 5;
  color: greenyellow;
}
.joined-613 {
  grid-column: 1/3;
  grid-row: 6;
  color: greenyellow;
}
.joined-635 {
  grid-column: 3/5;
  grid-row: 6;
  color: greenyellow;
}
.joined-668 {
  grid-column: 6/8;
  grid-row: 6;
  color: greenyellow;
}
.joined-635 {
  grid-column: 3/5;
  grid-row: 6;
  color: greenyellow;
}
.joined-657 {
  grid-column: 5/7;
  grid-row: 6;
  color: greenyellow;
}
.joined-624 {
  grid-column: 2/4;
  grid-row: 6;
  color: greenyellow;
}

.legends {
  margin-left: 5rem;
  margin-top: 3rem;
  color: black;
  width: 400px;
  height: 300px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
}
.green-yellow {
  color: black;
  font-size: 1.5rem;
  display: flex;
  justify-content: flex-start;
  width: 80%;
  margin: auto;
}
.green-yellow > span {
  width: 100px;
  height: 50px;
  background-color: greenyellow;
  margin-right: 2rem;
}
.cyan {
  color: black;
  font-size: 1.5rem;
  display: flex;
  justify-content: flex-start;
  width: 80%;
  margin: auto;
}
.cyan > span {
  width: 100px;
  height: 50px;
  background-color: #04d99d;
  margin-right: 2rem;
}
.ongoing {
  box-shadow: rgba(240, 46, 170, 0.4) 0px 5px, rgba(240, 46, 170, 0.3) 0px 10px,
    rgba(240, 46, 170, 0.2) 0px 15px, rgba(240, 46, 170, 0.1) 0px 20px,
    rgba(240, 46, 170, 0.05) 0px 25px;
}
.ongoing-start {
  box-shadow: rgba(240, 46, 170, 0.4) -5px 5px,
    rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px,
    rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px;
}
.ongoing-end {
  box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px,
    rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px,
    rgba(240, 46, 170, 0.05) 25px 25px;
}
.current {
  background-color: #04d99d;
  color: black;
}
footer {
  color: black;
  text-align: left;
  font-size: 2rem;
}
footer span {
  color: #04d99d;
}
.notFound {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
}
.upcoming {
  width: 100vw;
  height: 200px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 1rem;
}
.floating-box {
  flex: 1;
  margin: 1rem;
  height: 100%;
  padding: 0.2rem;
  font-size: 3rem;
  color: white;
  background-color: black;
  border: black;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 15px;
  box-shadow: rgba(240, 46, 170, 0.4) -5px 5px,
    rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px,
    rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px;
}
.floating-box > span {
  margin: 1.3rem 0;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}
@media screen and (max-width: 600px) {
  .selectors {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: fit-content;
  }
  h1 {
    color: #04d99d;
  }
  .heading {
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 2rem;
  }
  .heading h1 {
    padding: 0 1rem;
    color: black;
    font-size: 2.5rem;
  }
  select {
    font-size: 1.5rem;
  }
}
</style>
