export const timetables = {
  1: [
    {
      "CSE 1": [
        { Monday: [] },
        { Tuesday: [] },
        { Wednesday: [] },
        { Thursday: [] },
        { Friday: [] },
      ],
    },
    {"CSE 2":[
      { Monday: [] },
        { Tuesday: [] },
        { Wednesday: [] },
        { Thursday: [] },
        { Friday: [] },
    ]}
  ],
  2: [{
    "CSE 1":[
      {Monday: [{subject:"-",size:"single"},{subject:"DM Theory (LEC-21)",size:"single"},{subject:"CM Theory (LEC-21)",size:"single"},{subject:"OOP's Theory (LEC-21)",size:"single"},{subject:"DS Theory(LEC-21)",size:"single"},{subject:"DLCD Theory(LEC-31)",size:"single"},{subject:"DLCD Theory(LEC-21)",size:"single"}] },
      {Tuesday: [{subject:"DS Theory(LEC-31)",size:"single"},{subject:"DM Theory(LEC-21)",size:"single"},{subject:"GROUP A DLCD LAB (LAB-26) / GROUP B DS LAB (LAB-28)",size:"joined-335"},{subject:"GROUP A  OOP's Lab (LAB-27) / GROUP B CM Lab (Computer Centre)",size:"joined-357"},{subject:"-",size:"single"}], },
      { Wednesday: [{subject:"DM Theory(LEC-21)",size:"single"},{subject:"OOP's Theory(LEC-21)",size:"single"},{subject:"GROUP A DS LAB (LAB-28) / GROUP B OOP's LAB (LAB-27)",size:"joined-435"},{subject:"DLCD Theory(LEC-21)",size:"single"},{subject:"CM Theory (LEC-21)",size:"single"},{subject:"-",size:"single"}] },
      { Thursday: [{subject:"CM Theory (LEC-21)",size:"single"},{subject:"DS Theory (LEC-21)",size:"single"},{subject:"DLCD Theory (LEC-21)",size:"single"},{subject:"OOP's Theory(LEC-21)",size:"single"},{subject:"GROUP A CM LAB (LAB-Computer Centre) / GROUP B DLCD LAB (LAB-26)",size:"joined-557"},{subject:"IKS Theory(LEC-21)",size:"single"}] },
      { Friday: [{subject:"CM Theory (LEC-21)",size:"single"},{subject:"IKS Theory (LEC-21)",size:"single"},{subject:"DM Theory(LEC-21)",size:"single"},{subject:"OOP's Theory (LEC-21)",size:"single"},{subject:"DS Theory(LEC-21)",size:"single"},{subject:"-",size:"single"},{subject:"-",size:"single"}] },
      {Home:"LEC-21"}
    ]

  },{
    "CSE 2": [
      {Monday: [{subject:"OOP's Theory (LEC-22)",size:"single"},{subject:"DS Theory (LEC-22)",size:"single"},{subject:"DLCD Theory (LEC-32)",size:"single"},{subject:"IKS Theory (LEC-32)",size:"single"},{subject:"DM Theory (LEC-22)",size:"single"},{subject:"CM Theory (LEC-22)",size:"single"},{subject:"-",size:"single"}] },
      {Tuesday: [{subject:"-",size:"single"},{subject:"IKS Theory(LEC-22)",size:"single"},{subject:"CM Theory(LEC-32)",size:"single"},{subject:"OOP's Theory(LEC-22)",size:"single"},{subject:"DM Theory (LEC-22)",size:"single"},{subject:"DS Theory (LEC-22)",size:"single"},{subject:"DLCD Theory (LEC-22)",size:"single"}] },
      { Wednesday: [{subject:"GROUP A CM LAB (LAB-Computer Centre) / GROUP B DS LAB (LAB-28)",size:"joined-413"},{subject:"DLCD Theory(LEC-22)",size:"single"},{subject:"CM Theory(LEC-22)",size:"single"},{subject:"OOP'S Theory(LEC-22)",size:"single"},{subject:"GROUP A OOP'S LAB (LAB-27) / GROUP B DLCD LAB (LAB-26)",size:"joined-468"}] },
      { Thursday: [{subject:"DM Theory (LEC-22)",size:"single"},{subject:"OOP's Theory(LEC-22)",size:"single"},{subject:"CM Theory(LEC-22)",size:"single"},{subject:"DS Theory(LEC-22)",size:"single"},{subject:"-",size:"single"},{subject:"-",size:"single"},{subject:"-",size:"single"}] },
      { Friday: [{subject:"DM Theory (LEC-32)",size:"single"},{subject:"DS Theory (LEC-22)",size:"single"},{subject:"GROUP A DS LAB (LAB-28) / GROUP B CM LAB (LAB-Computer Centre)",size:"joined-635"},{subject:"GROUP A DLCD LAB (LAB-26) / GROUP B OOP's LAB (LAB-27)",size:"joined-657"},{subject:"DLCD Theory(LEC-22)",size:"single"}] },
      {Home:"LEC-22"}
    ]
  },{
    "CSE 3":[
      {Monday: [{subject:"IKS Theory (LEC-22)",size:"single"},{subject:"DS Theory (LEC-21)",size:"single"},{subject:"GROUP A OOP's LAB (LAB-27) / GROUP B DS LAB(LAB-28)",size:"joined"},{subject:"GROUP A CM LAB (LAB-Computer Centre) / GROUP B DLCD LAB (LAB-8)",size:"joined-13"},{subject:"DLCD Theory (LEC-22)",size:"single"}] },
      {Tuesday: [{subject:"-",size:"single"},{subject:"DM Theory(LEC-22)",size:"single"},{subject:"OOP's Theory(LEC-22)",size:"single"},{subject:"CM Theory(LEC-22)",size:"single"},{subject:"DS Theory (LEC-22)",size:"single"},{subject:"IKS Theory (LEC-32)",size:"single"},{subject:"DLCD Theory (LEC-22)",size:"single"}], },
      { Wednesday: [{subject:"DM Theory(LEC-22)",size:"single"},{subject:"OOP's Theory(LEC-32)",size:"single"},{subject:"DLCD Theory(LEC-22)",size:"single"},{subject:"CM Theory(LEC-22)",size:"single"},{subject:"DS Theory (LEC-21)",size:"single"},{subject:"GROUP A DLCD LAB(LAB-8)",size:"joined-14"}] },
      { Thursday: [{subject:"DM Theory (LEC-22)",size:"single"},{subject:"OOP's Theory(LEC-22)",size:"single"},{subject:"DLCD Theory(LEC-22)",size:"single"},{subject:"CM Theory(LEC-32)",size:"single"},{subject:"GROUP B OOP's LAB(LAB-27)",size:"joined-16"},{subject:"-",size:"single"}] },
      { Friday: [{subject:"-",size:"single"},{subject:"DM Theory (LEC-22)",size:"single"},{subject:"GROUP A DS LAB (LAB-28) / GROUP B CM LAB (LAB-Computer Centre)",size:"joined-17"},{subject:"DS Theory(LEC-22)",size:"single"},{subject:"CM Theory (LEC-22)",size:"single"},{subject:"OOP's Theory(LEC-22)",size:"single"}] },
      {Home:"LEC-22"}
    ]
  },{
    "IT 1":[
      {Monday: [{subject:"-",size:"single"},{subject:"GROUP A DS LAB (LAB-14)",size:"joined-224"},{subject:"DS Theory (LEC-23)",size:"single"},{subject:"CM THEORY(LEC-23)",size:"single"},{subject:"OOP's THEORY (LEC-23)",size:"single"},{subject:"-",size:"single"}] },
      {Tuesday: [{subject:"-",size:"single"},{subject:"DM Theory(LEC-23)",size:"single"},{subject:"CM Theory(LEC-23)",size:"single"},{subject:"DS Theory(LEC-23)",size:"single"},{subject:"DLCD Theory (LEC-23)",size:"single"},{subject:"IKS Theory (LEC-23)",size:"single"},{subject:"OOP's THEORY (LEC-23)",size:"single"}], },
      { Wednesday: [{subject:"GROUP A DLCD LAB (LAB-26)/GROUP B CM LAB (LAB-12)",size:"joined-413"},{subject:"DS Theory(LEC-23)",size:"single"},{subject:"DM Theory(LEC-23)",size:"single"},{subject:"DLCD Theory(LEC-23)",size:"single"},{subject:"CM THEORY (LEC-23)",size:"single"},{subject:"OOP's THEORY (LEC-23)",size:"single"}] },
      { Thursday: [{subject:"GROUP B OOP's LAB (LAB-12)",size:"joined-513"},{subject:"DLCD Theory(LEC-23)",size:"single"},{subject:"DM Theory(LEC-23)",size:"single"},{subject:"GROUP A CM LAB(LAB-12)/GROUP B DS LAB(LAB-14)",size:"joined-557"},{subject:"DS Theory(LEC-23)",size:"single"}] },
      { Friday: [{subject:"GROUP A OOP's LAB (LAB-12)/GROUP B DLCD LAB (LAB-26)",size:"joined-613"},{subject:"IKS Theory (LEC-23)",size:"single"},{subject:"CM THEORY(LEC-23)",size:"single"},{subject:"DM Theory(LEC-23)",size:"single"},{subject:"OOP's Theory (LEC-23)",size:"single"},{subject:"DLCD Theory(LEC-23)",size:"single"}] },
      {Home:"LEC-23"}
    ]
  },{
    "IT 2":[
      {Monday: [{subject:"-",size:"single"},{subject:"IKS Theory (LEC-24)",size:"single"},{subject:"DLCD Theory(LEC-24)",size:"single"},{subject:"CM Theory(LEC-24)",size:"single"},{subject:"DM Theory(LEC-24)",size:"single"},{subject:"GROUP A DLCD LAB (LAB-26)/GROUP B CM LAB (LAB-12)",size:"joined-268"}] },
      {Tuesday: [{subject:"-",size:"single"},{subject:"DLCD Theory(LEC-24)",size:"single"},{subject:"OOP's Theory(LEC-24)",size:"single"},{subject:"CM Theory(LEC-24)",size:"single"},{subject:"DM Theory (LEC-24)",size:"single"},{subject:"CM Theory(LEC-24)",size:"single"},{subject:"DS Theory(LEC-24)",size:"single"}], },
      { Wednesday: [{subject:"-",size:"single"},{subject:"OOP's Theory(LEC-24)",size:"single"},{subject:"DS Theory(LEC-24)",size:"single"},{subject:"IKS Theory(LEC-24)",size:"single"},{subject:"GROUP A DS LAB (LAB-14)/GROUP B OOP's LAB (LAB-12)",size:"joined-457"},{subject:"DLCD THEORY (LEC-24)",size:"single"}] },
      { Thursday: [{subject:"GROUP A CM LAB/GROUP B DLCD LAB(LAB-26)",size:"joined-513"},{subject:"DS Theory (LEC-24)",size:"single"},{subject:"CM Theory (LEC-24)",size:"single"},{subject:"DM Theory (LEC-24)",size:"single"},{subject:"OOP's Theory (LEC-24)",size:"single"},{subject:"-",size:"single"}] },
      { Friday: [{subject:"-",size:"single"},{subject:"DS Theory(LEC-24)",size:"single"},{subject:"OOP's Theory (LEC-24)",size:"single"},{subject:"DLCD THEORY(LEC-24)",size:"single"},{subject:"GROUP A OOP's LAB(LAB-12)/GROUP B DS LAB(LAB-14)",size:"joined-657"},{subject:"DM Theory(LEC-24)",size:"single"}] },
      {Home:"LEC-24"}
    ]
  },{
    "IT 3":[
      
    ]
  },{
    "ECE 1":[
      {Monday: [{subject:"CM Theory (LEC-31)",size:"single"},{subject:"AE-1 Theory (LEC-31)",size:"single"},{subject:"AC Theory (LEC-31)",size:"single"},{subject:"S&S Theory (LEC-31)",size:"single"},{subject:"GROUP A CM LAB (LAB-25) / GROUP B AE-1 LAB(LAB-8)",size:"joined-257"},{subject:"DLCD Theory (LEC-31)",size:"single"}] },
      {Tuesday: [{subject:"-",size:"single"},{subject:"S&S Theory(LEC-31)",size:"single"},{subject:"CM Theory (LEC-31)",size:"single"},{subject:"AE-1 Theory (LEC-31)",size:"single"},{subject:"GROUP A DLCD LAB (LAB-36)/GROUP B CM LAB (LAB-26)",size:"joined-357"},{subject:"DLCD Theory (LEC-31)",size:"single"}], },
      {Wednesday: [{subject:"IKS Theory(LEC-31)",size:"single"},{subject:"CM Theory(LEC-31)",size:"single"},{subject:"S&S Theory (LEC-31)",size:"single"},{subject:"DLCD THEORY(LEC-31)",size:"single"},{subject:"AC Theory(LEC-31)",size:"single"},{subject:"GROUP B AC LAB (LAB-4)",size:"joined-468"}] },
      {Thursday: [{subject:"GROUP A S&S LAB (LAB-25)",size:"joined-513"},{subject:"S&S Theory(LEC-31)",size:"single"},{subject:"AE-1 Theory(LEC-31)",size:"single"},{subject:"AC Theory(LEC-31)",size:"single"},{subject:"IKS (LEC-31)",size:"single"},{subject:"DLCD (LEC-31)",size:"single"}] },
      {Friday: [{subject:"AE-1 (LEC-31)",size:"single"},{subject:"AC Theory(LEC-31)",size:"single"},{subject:"GROUP A AC LAB (LAB-4)/GROUP B DLCD LAB(LAB-36)",size:"joined-635"},{subject:"GROUP A AE-1 LAB (LAB-8)/GROUP B S&S LAB(LAB-25)",size:"joined-657"},{subject:"CM Theory(LEC-31)",size:"single"}] },
      {Home:"LEC-31"}
    ]
  },{
    "ECE 2":[
      {Monday: [{subject:"AC Theory(LEC-32)",size:"single"},{subject:"S&S Theory(LEC-32)",size:"single"},{subject:"GROUP A DLCD LAB (LAB-36)/GROUP B AE-1 LAB (LAB-8)",size:"joined-235"},{subject:"DLCD Theory (LEC-32)",size:"single"},{subject:"IKS THEORY (LEC-32)",size:"single"},{subject:"AE-1 Theory (LEC-32)",size:"single"}] },
      {Tuesday: [{subject:"-",size:"single"},{subject:"DLCD Theory(LEC-32)",size:"single"},{subject:"GROUP A S&S LAB (LAB-25)/GROUP B AC LAB (LAB-4)",size:"joined-335"},{subject:"S&S Theory(LEC-32)",size:"single"},{subject:"CM THEORY (LEC-32)",size:"single"},{subject:"AE-1 Theory (LEC-32)",size:"single"}] },
      {Wednesday: [{subject:"S&S Theory(LEC-32)",size:"single"},{subject:"AC Theory(LEC-32)",size:"single"},{subject:"GROUP A AE-1 LAB (LAB-8)/GROUP B CM LAB (LAB-25)",size:"joined-435"},{subject:"AE-1 Theory(LEC-32)",size:"single"},{subject:"CM Theory(LEC-32)",size:"single"},{subject:"DLCD Theory(LEC-32)",size:"single"}] },
      {Thursday: [{subject:"GROUP B DLCD LAB (LAB-36)",size:"joined-513"},{subject:"CM Theory(LEC-32)",size:"single"},{subject:"AC Theory(LEC-32)",size:"single"},{subject:"S&S Theory (LEC-32)",size:"single"},{subject:"GROUP A AC LAB (LAB-4) / GROUP B S&S LAB (LAB-25)",size:"joined-568"}] },
      {Friday: [{subject:"GROUP A CM LAB (LAB-26)",size:"joined-613"},{subject:"CM Theory(LEC-32)",size:"single"},{subject:"IKS Theory(LEC-32)",size:"single"},{subject:"AC Theory(LEC-32)",size:"single"},{subject:"DLCD Theory(LEC-32)",size:"single"},{subject:"AE-1 Theory(LEC-32)",size:"single"}] },
      {Home:"LEC-32"}
    ]
},{
  "ECE 3":[
    
  ]
},{
  "AIML":[
    {Monday: [{subject:"GROUP A FDS LAB (LAB-16B)",size:"joined-213"},{subject:"FDS Theory (BVS-14)",size:"single"},{subject:"DS Theory (BVS-14)",size:"single"},{subject:"GROUP A WP LAB (LAB-16B)/GROUP B PAI LAB (LAB-13)",size:"joined-257"},{subject:"PSLA Theory (BVS-14)",size:"single"}] },
    {Tuesday: [{subject:"DLD Theory (BVS-14)",size:"single"},{subject:"PAI Theory (BVS-14)",size:"single"},{subject:"GROUP A DS LAB (LAB-16B)/GROUP B DLD LAB (LAB-36)",size:"joined-335"},{subject:"GROUP A PAI LAB (LAB-12)/GROUP B WP LAB (LAB-16B)",size:"joined-357"},{subject:"UHV-II Theory (BVS-14)",size:"single"}] },
    {Wednesday: [{subject:"GROUP B FDS LAB (LAB-16B)",size:"joined-413"},{subject:"PAI Theory (BVS-14)",size:"single"},{subject:"PSLA Theory (BVS-14)",size:"single"},{subject:"GROUP A DLD LAB (LAB-36)/GROUP B DS LAB (LAB-16B)",size:"joined-457"},{subject:"UHV-II Theory(LEC-22)",size:"single"}] },
    {Thursday: [{subject:"CRST Theory (BVS-14)",size:"single"},{subject:"DLD Theory (BVS-14)",size:"single"},{subject:"PSLA Theory (BVS-14)",size:"single"},{subject:"FDS Theory(BVS-14)",size:"single"},{subject:"PAI Theory (BVS-14)",size:"single"},{subject:"DS Theory (BVS-14)",size:"single"},{subject:"UHV-II Theory (BVS-14)",size:"single"}] },
    {Friday: [{subject:"-",size:"single"},{subject:"DLD Theory (BVS-14)",size:"single"},{subject:"FDS Theory(BVS-14)",size:"single"},{subject:"SR Theory (BVS-14)",size:"single"},{subject:"CRST Theory (BVS-14)",size:"single"},{subject:"PSLA Theory (BVS-14)",size:"single"},{subject:"DS Theory (BVS-14)",size:"single"}] },
    {Home:"BVS-14"}
  ]
},{
  "AIDS":[
    {Monday: [{subject:"-",size:"single"},{subject:"PAI Theory(BVS-16)",size:"single"},{subject:"GROUP A WP LAB (LAB-16B)/GROUP B PAI LAB (LAB-12)",size:"joined-235"},{subject:"DLD Theory (BVS-16)",size:"single"},{subject:"DS Theory (BVS-16)",size:"single"},{subject:"UHV-II Theory(BVS-16)",size:"single"}] },
    {Tuesday: [{subject:"GROUP A DS LAB (LAB-16B)",size:"joined-313"},{subject:"DLD Theory(BVS-16)",size:"single"},{subject:"PSLA Theory(BVS-16)",size:"single"},{subject:"CRST Theory(BVS-16)",size:"single"},{subject:"FDS Theory (BVS-16)",size:"single"},{subject:"SR THEORY(BVS-16)",size:"single"}] },
    {Wednesday: [{subject:"DS Theory(BVS-16)",size:"single"},{subject:"DLD Theory(BVS-16)",size:"single"},{subject:"GROUP A FDS LAB (LAB-16B)/GROUP B DLD LAB (LAB-36)",size:"joined-435"},{subject:"PSLA Theory(BVS-16)",size:"single"},{subject:"FDS Theory(BVS-16)",size:"single"},{subject:"UHV-II Theory(BVS-16)",size:"single"}] },
    {Thursday: [{subject:"DS Theory(BVS-16)",size:"single"},{subject:"PAI Theory(BVS-16)",size:"single"},{subject:"GROUP A PAI LAB (LAB-12)/GROUP B WP LAB (LAB-16B)",size:"joined-535"},{subject:"PSLA Theory(BVS-16)",size:"single"},{subject:"GROUP B FDS LAB (LAB-16B)",size:"joined-568"}] },
    {Friday: [{subject:"CRST Theory(BVS-16)",size:"single"},{subject:"PAI Theory(BVS-16)",size:"single"},{subject:"PSLA Theory(BVS-16)",size:"single"},{subject:"UHV-II Theory(BVS-16)",size:"single"},{subject:"GROUP A DLD LAB (LAB-36)/GROUP B DS LAB (LAB-16B)",size:"joined-657"},{subject:"FDS Theory(BVS-16)",size:"single"}] },
    {Home:"BVS-16"}
  ]
}],
  3: [{
    "CSE 1": [
      { Monday: [{subject:"IM Theory(LAB-33)",size:"single"},{subject:"Java Theory(LAB-33)",size:"single"},{subject:"DC Tutorial (LAB-33)",size:"single"},{subject:"CSP Theory(LAB-33)",size:"single"},{subject:"ADA Theory(LAB-33)",size:"single"},{subject:"SE Theory(LAB-33)",size:"single"},{subject:"Java Tutorial(LAB-33)",size:"single"}] },
      { Tuesday: [{subject:"GROUP B SE_C LAB (LAB-38)",size:"joined-313"},{subject:"GROUP B CSP_C LAB (LAB-38)",size:"joined-335"},{subject:"GROUP A DC_C Lab LAB(4) / GROUP B ADA_C Lab (LAB 28)",size:"joined-357"},{subject:"DC Theory(LAB-33)",size:"single"}] },
      { Wednesday: [{subject:"IM Theory(LAB-33)",size:"single"},{subject:"DC Theory(LAB-33)",size:"single"},{subject:"SE Theory(LAB-33)",size:"single"},{subject:"ADA Tutorial(LAB-33)",size:"single"},{subject:"ADA Theory(LAB-33)",size:"single"},{subject:"Java Theory(LAB-33)",size:"single"},{subject:"SE Tutorial(LAB-33)",size:"single"}] },
      { Thursday: [{subject:"GROUP A JAVA_C LAB (LAB-32)/GROUP B DC_C LAB (LAB-4)",size:"joined-513"},{subject:"SE Theory(LAB-33)",size:"single"},{subject:"CSP Theory(LAB-33)",size:"single"},{subject:"IM Theory(LAB-33)",size:"single"},{subject:"GROUP A ADA_C LAB (LAB 28)",size:"joined-557"}] },
      { Friday: [{subject:"GROUP A CSP Lab (LAB-18)",size:"joined-613"},{subject:"GROUP A SE_C Lab (LAB-38) / GROUP B JAVA_C Lab (LAB-32)",size:"joined-635"},{subject:"JAVA Theory(LAB-33)",size:"single"},{subject:"DC Theory(LAB-33)",size:"single"},{subject:"ADA Theory(LAB-33)",size:"single"}] },
      {Home:"Lab 33"}
    ],
  },{
    "CSE 2": [
      {Monday: [{subject:"GROUP A DC_C LAB (LAB-4)/GROUP B ADA_C LAB (LAB-28)",size:"joined-213"},{subject:"GROUP A JAVA_C LAB (LAB-32)/GROUP B SE_C LAB (LAB-38)",size:"joined-235"},{subject:"GROUP A CSP_C LAB (LAB-18)/GROUP B DC_C LAB (LAB-4)",size:"joined-257"},{subject:"-",size:"single"}] },
      {Tuesday: [{subject:"GROUP A ADA LAB (LAB-28)",size:"joined-313"},{subject:"IM Theory(LAB-34)",size:"single"},{subject:"SE Theory(LAB-34)",size:"single"},{subject:"ADA Theory(LAB-34)",size:"single"},{subject:"CSP Theory(LAB-34)",size:"single"},{subject:"-",size:"single"}] },
      {Wednesday: [{subject:"GROUP A JAVA LAB (LAB-32)/GROUP B SE LAB (LAB-38)",size:"joined-413"},{subject:"JAVA Theory (LAB-34)",size:"single"},{subject:"ADA Tutorial (LAB-34)",size:"single"},{subject:"DC Theory(LAB-34)",size:"single"},{subject:"GROUP B CSP LAB (LAB-18)",size:"joined-468"}] },
      {Thursday: [{subject:"SE Theory (LAB-34)",size:"single"},{subject:"ADA Theory (LAB-34)",size:"single"},{subject:"DC Theory (LAB-34)",size:"single"},{subject:"IM Theory(LAB-34)",size:"single"},{subject:"JAVA Theory (LAB-34)",size:"single"},{subject:"CSP Theory (LAB-34)",size:"single"},{subject:"JAVA Tutorial (LAB-34)",size:"single"}] },
      {Friday: [{subject:"DC Tutorial ",size:"single"},{subject:"JAVA Theory(LAB-34)",size:"single"},{subject:"DC Theory (LAB-34)",size:"single"},{subject:"ADA THEORY(LAB-34)",size:"single"},{subject:"SE Theory (LAB-34)",size:"single"},{subject:"IM Theory (LAB-34)",size:"single"},{subject:"SE Tutorial(LAB-34)",size:"single"}] },
      {Home:"Lab 33"}
    ],
  },{
    "CSE 3":[
      
    ]
  },{
    "ECE 1":[
      {Monday: [{subject:"GROUP A CSP_E LAB (LAB-18)/GROUP B DSD_E LAB (LAB-26)",size:"joined-213"},{subject:"GROUP A DSD_E LAB (LAB-26)/GROUP B DC_E LAB (LAB-4)",size:"joined-235"},{subject:"M&M Theory (BVS-11)",size:"single"},{subject:"IM Theory(BVS-11)",size:"single"},{subject:"DSD Theory (BVS-11)",size:"single"}] },
      {Tuesday: [{subject:"-",size:"single"},{subject:"M&M Theory (BVS-11)",size:"single"},{subject:"GROUP A M&M_E LAB (LAB-3)/GROUP B CONT. SYSTEM LAB (LAB-31)",size:"joined-335"},{subject:"DSD Theory(BVS-11)",size:"single"},{subject:"DC Theory (BVS-11)",size:"single"},{subject:"-",size:"single"}] },
      {Wednesday: [{subject:"GROUP A DC_E LAB (LAB-4)/GROUP B CSP_E LAB (LAB-18)",size:"joined-413"},{subject:"CSP Theory(BVS-11)",size:"single"},{subject:"IM Theory(BVS-11)",size:"single"},{subject:"DSD THEORY(BVS-11)",size:"single"},{subject:"CONT. SYSTEM Theory(BVS-11)",size:"single"},{subject:"DC Theory (BVS-11)",size:"single"}] },
      {Thursday: [{subject:"-",size:"single"},{subject:"DC Tutorial (BVS-11)",size:"single"},{subject:"GROUP A CONT. SYSTEM LAB(LAB-31)/GROUP B M&M LAB(LAB-3)",size:"joined-535"},{subject:"M&M Theory (BVS-11)",size:"single"},{subject:"CONT. SYSTEM Theory(BVS-11)",size:"single"},{subject:"DC Theory(BVS-11)",size:"single"}] },
      {Friday: [{subject:"-",size:"single"},{subject:"CONT. SYSTEM Tutorial(BVS-11)",size:"single"},{subject:"CSP Theory(BVS-11)",size:"single"},{subject:"M&M Tutorial(BVS-11)",size:"single"},{subject:"IM Theory(BVS-11)",size:"single"},{subject:"DSD Tutorial(BVS-11)",size:"single"},{subject:"CONT. SYSTEM Theory(BVS-11)",size:"single"}] },
      {Home:"BVS-11"}
    ]
  },{
    "ECE 2":[
      {Monday: [{subject:"-",size:"single"},{subject:"-",size:"single"},{subject:"CONT. SYSTEM Theory(BVS-12)",size:"single"},{subject:"IM Theory (BVS-12)",size:"single"},{subject:"DC Theory(BVS-12)",size:"single"},{subject:"CONT. SYSTEM Tutorial(BVS-12)",size:"single"},{subject:"M&M THEORY (BVS-12)",size:"single"}] },
      {Tuesday: [{subject:"GROUP A DC_E LAB (LAB-4)/GROUP B CONT. SYS._E LAB (LAB-31)",size:"joined-313"},{subject:"DC Theory(BVS-12)",size:"single"},{subject:"IM Theory(BVS-12)",size:"single"},{subject:"M&M Theory(BVS-12)",size:"single"},{subject:"GROUP A DSD_E LAB (LAB-26)/GROUP B CSP_E LAB (LAB-18)",size:"joined-368"}] },
      {Wednesday: [{subject:"CONT. SYSTEM Theory(BVS-12)",size:"single"},{subject:"DSD Tutorial(BVS-12)",size:"single"},{subject:"GROUP A M&M_E LAB (LAB-3)/GROUP B DC_E LAB (LAB-4)",size:"joined-435"},{subject:"M&M Tutorial(BVS-12)",size:"single"},{subject:"CSP Theory(BVS-12)",size:"single"},{subject:"M&M THEORY(BVS-12)",size:"single"}] },
      {Thursday: [{subject:"-",size:"single"},{subject:"-",size:"single"},{subject:"CONT. SYSTEM Theory(BVS-12)",size:"single"},{subject:"DSD Theory(BVS-12)",size:"single"},{subject:"DC Theory (BVS-12)",size:"single"},{subject:"DC Tutorial(BVS-12)",size:"single"},{subject:"CSP Theory(BVS-12)",size:"single"},{subject:"DSD Tutorial(BVS-12)",size:"single"}] },
      {Friday: [{subject:"DSD Theory(BVS-12)",size:"single"},{subject:"IM Theory(BVS-12)",size:"single"},{subject:"GROUP A CSP_E LAB (LAB-18)/GROUP B DSD LAB (LAB-26)",size:"joined-635"},{subject:"GROUP A CONT. SYS. LAB (LAB-31)/GROUP B M&M LAB (LAB-3)",size:"joined-657"},{subject:"-",size:"single"}], },
      {Home:"BVS-12"}
    ]
  },{
    "ECE 3":[
      
    ]
  },{
    "IT 1":[
      {Monday: [{subject:"-",size:"single"},{subject:"SE Theory(LEC-33)",size:"single"},{subject:"COMM. SYSTEM Theory(LEC-33)",size:"single"},{subject:"JAVA Theory(LEC-33)",size:"single"},{subject:"GROUP A COMM. SYS._I LAB (LAB-3) / GROUP B ADA_I LAB (LAB-14)",size:"joined-257"},{subject:"ADA Theory (LEC-33)",size:"single"}] },
      {Tuesday: [{subject:"GROUP A CSP_I LAB (LAB-18)/GROUP B COMM. SYS. LAB (LAB-3)",size:"joined-313"},{subject:"ADA Theory(LEC-33)",size:"single"},{subject:"JAVA Theory(LEC-33)",size:"single"},{subject:"CSP Theory(LEC-33)",size:"single"},{subject:"COMM. SYSTEM Theory(LEC-33)",size:"single"},{subject:"IM Theory(LEC-33)",size:"single"}], },
      {Wednesday: [{subject:"-",size:"single"},{subject:"IM Theory(LEC-33)",size:"single"},{subject:"COMM. SYSTEM Tutorial(LEC-33)",size:"single"},{subject:"SE Tutorial(LEC-33)",size:"single"},{subject:"CSP Theory(LEC-33)",size:"single"},{subject:"SE Theory (LEC-33)",size:"single"},{subject:"COMM. SYSTEM Theory(LEC-33)",size:"single"}], },
      {Thursday: [{subject:"-",size:"single"},{subject:"GROUP A ADA_I LAB (LAB-14)/GROUP B JAVA LAB (LAB-13)",size:"joined-524"},{subject:"ADA Theory (LEC-33)",size:"single"},{subject:"JAVA Tutorial(LEC-33)",size:"single"},{subject:"ADA Tutorial(LEC-33)",size:"single"},{subject:"-",size:"single"}] },
      {Friday: [{subject:"SE Theory(LEC-33)",size:"single"},{subject:"GROUP A JAVA_I LAB (LAB-13)/GROUP B SE_I LAB (LAB-17)",size:"joined-624"},{subject:"JAVA Theory(LEC-33)",size:"single"},{subject:"GROUP A SE_I LAB (LAB-17)/GROUP B CSP_I LAB (LAB-18)",size:"joined-657"},{subject:"IM THEORY(LEC-33)",size:"single"}] },
      {Home:"Lec 33"}
    ]
  },{
    "IT 2":[
      {Monday: [{subject:"CSP Theory (LEC-34)",size:"single"},{subject:"JAVA Theory (LEC-34)",size:"single"},{subject:"GROUP A SE_I LAB (LAB-17)/GROUP B CSP_I LAB (LAB-18)",size:"joined-235"},{subject:"COMM. SYS. Theory (LEC-34)",size:"single"},{subject:"SE Theory (LEC-34)",size:"single"},{subject:"IM Theory (LEC-34)",size:"single"}] },
      {Tuesday: [{subject:"-",size:"single"},{subject:"JAVA Theory(LEC-34)",size:"single"},{subject:"COMM. SYS. Theory (LEC-34)",size:"single"},{subject:"SE Theory (LEC-34)",size:"single"},{subject:"GROUP A JAVA LAB (LAB-13)/GROUP B ADA LAB (LAB-14)",size:"joined-357"},{subject:"ADA Theory (LEC-34)",size:"single"}], },
      { Wednesday: [{subject:"-",size:"single"},{subject:"GROUP A ADA_I LAB (LAB-14)/GROUP B SE_I LAB (LAB-17)",size:"joined-424"},{subject:"ADA Theory (LEC-34)",size:"single"},{subject:"GROUP A COMM. SYS. LAB (LAB-3)/GROUP B JAVA LAB (LAB-13)",size:"joined-457"},{subject:"-",size:"single"}] },
      { Thursday: [{subject:"-",size:"single"},{subject:"IM Theory (LEC-34)",size:"single"},{subject:"JAVA Theory(LEC-34)",size:"single"},{subject:"COMM. SYS. Theory (LEC-34)",size:"single"},{subject:"GROUP A CSP LAB (LAB-18)/GROUP B COMM. SYS.LAB (LAB-3)",size:"joined-557"},{subject:"SE TUTORIAL (LEC-34)",size:"single"}] },
      { Friday: [{subject:"IM Theory (LEC-34)",size:"single"},{subject:"JAVA Tutorial(LEC-34)",size:"single"},{subject:"ADA Theory (LEC-34)",size:"single"},{subject:"SE THEORY(LEC-34)",size:"single"},{subject:"ADA TUTORIAL (LEC-34)",size:"single"},{subject:"COMM. SYS. Tutorial (LEC-34)",size:"single"},{subject:"CSP Theory (LEC-34)",size:"single"}] },
      {Home:"LEC-34"}
    ]
  },{
    "IT 3":[
      
    ]
  }],
  4: [],
};
